<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Condicion de Pago" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { PaymentCondition } from '../../../models/cxp/PaymentCondition';
import Catalog from "../../../components/templates/BasicCatalog.vue";

export default {
  props: {
    voucher_type: null
  },
  data() {
    return {
      test: null,
      entity: new PaymentCondition(),
      inputs: [
        new CatalogInput("Clave", "key_name", "text", { cols: 2 }),
        new CatalogInput("Nombre", "name", "text", { cols: 4 }),
        new CatalogInput("Contado", "is_counted_payment", "switch", { cols: 2 }),
        new CatalogInput("Anticipo", "is_anticipated_payment", "switch", { cols: 2 }),
        new CatalogInput("Dias de Pago", "days", "number", { cols: 2 }),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })      
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Contado", "is_counted_payment", { type: "boolean" }),
        new HeaderGrid("Anticipo", "is_anticipated_payment", { type: "boolean" }),
        new HeaderGrid("Dias de Pago", "days")
      ],
      validate: new ValidateForm()    
      }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new PaymentCondition(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new PaymentCondition(this.session); 
  }
}
</script>

<style>

</style>